// costingStockSettingReducer.js
import { getToken, returnError } from './helperFunc';
import {
    syncStaffOutletPermissionsAction,
    syncDistributorUserOutletPermissionsAction,
    syncStaffOperatorControllerAction,
    syncDistributorUserOperatorControllerAction
} from './requestEndpoints';

const SYNC_DATA_1_SUCCESS = 'SYNC_DATA_1_SUCCESS';
const SYNC_DATA_1_FAIL = 'SYNC_DATA_1_FAIL';

const SYNC_DATA_2_SUCCESS = 'SYNC_DATA_2_SUCCESS';
const SYNC_DATA_2_FAIL = 'SYNC_DATA_2_FAIL';

const SYNC_DATA_3_SUCCESS = 'SYNC_DATA_3_SUCCESS';
const SYNC_DATA_3_FAIL = 'SYNC_DATA_3_FAIL';

const SYNC_DATA_4_SUCCESS = 'SYNC_DATA_4_SUCCESS';
const SYNC_DATA_4_FAIL = 'SYNC_DATA_4_FAIL';

const CLEAR_SYNC_DATA_ERROR = 'CLEAR_SYNC_DATA_ERROR';
const SET_SYNC_DATA_LOADING = 'SET_SYNC_DATA_LOADING'


export const syncStaffOutletPermissions = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SYNC_DATA_ERROR });
        dispatch({ type: SET_SYNC_DATA_LOADING });

        const { adminReducer } = getState();
        const token = adminReducer ? adminReducer.token : '';
        // console.log('stateData', adminReducer);

        const { data } = await syncStaffOutletPermissionsAction(token);

        dispatch({ type: SYNC_DATA_1_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SYNC_DATA_1_FAIL, payload: returnError(error) });
    }
};

export const syncDistributorUserOutletPermissions = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SYNC_DATA_ERROR });
        dispatch({ type: SET_SYNC_DATA_LOADING });

        const { adminReducer } = getState();
        const token = adminReducer ? adminReducer.token : '';
        // console.log('stateData', adminReducer);

        const { data } = await syncDistributorUserOutletPermissionsAction(token);

        dispatch({ type: SYNC_DATA_2_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SYNC_DATA_2_FAIL, payload: returnError(error) });
    }
};

export const syncStaffOperatorController = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SYNC_DATA_ERROR });
        dispatch({ type: SET_SYNC_DATA_LOADING });

        const { adminReducer } = getState();
        const token = adminReducer ? adminReducer.token : '';
        // console.log('stateData', adminReducer);

        const { data } = await syncStaffOperatorControllerAction(token);

        dispatch({ type: SYNC_DATA_3_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SYNC_DATA_3_FAIL, payload: returnError(error) });
    }
};

export const syncDistributorUserOperatorController = () => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_SYNC_DATA_ERROR });
        dispatch({ type: SET_SYNC_DATA_LOADING });

        const { adminReducer } = getState();
        const token = adminReducer ? adminReducer.token : '';
        // console.log('stateData', adminReducer);

        const { data } = await syncDistributorUserOperatorControllerAction(token);

        dispatch({ type: SYNC_DATA_4_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SYNC_DATA_4_FAIL, payload: returnError(error) });
    }
};


const initialState = {
    language: 'en',
    loading: false,
    syncDone1: null,
    syncDone2: null,
    syncDone3: null,
    syncDone4: null,
};

export const syncDataManagementReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_SYNC_DATA_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CLEAR_SYNC_DATA_ERROR:
            return {
                ...state,
                error: null,
            };

        case SYNC_DATA_1_SUCCESS:
            return {
                ...state,
                syncDone1: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case SYNC_DATA_1_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case SYNC_DATA_2_SUCCESS:
            return {
                ...state,
                syncDone2: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case SYNC_DATA_2_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SYNC_DATA_3_SUCCESS:
            return {
                ...state,
                syncDone3: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case SYNC_DATA_3_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SYNC_DATA_4_SUCCESS:
            return {
                ...state,
                syncDone4: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };

        case SYNC_DATA_4_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
