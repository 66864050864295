// chemicalStockSettingReducer.js
import { getToken, returnError } from './helperFunc';
import {
    getChemicalTypeAction,
    addChemicalTypeAction,
    assignChemicalTypeAction,
    editChemicalTypeAction,
    deleteChemicalTypeAction,
} from './requestEndpoints';

import { LOGOUT_SUCCESS } from './userReducer';

const SET_CHEMICAL_TYPE_LOADING = 'SET_CHEMICAL_TYPE_LOADING';
const CLEAR_CHEMICAL_TYPE_ERROR = 'CLEAR_CHEMICAL_TYPE_ERROR';

const ADD_CHEMICAL_TYPE_SUCCESS = 'ADD_CHEMICAL_TYPE_SUCCESS';
const ADD_CHEMICAL_TYPE_FAIL = 'ADD_CHEMICAL_TYPE_FAIL';

const ASSIGN_CHEMICAL_TYPE_SUCCESS = 'ASSIGN_CHEMICAL_TYPE_SUCCESS';
const ASSIGN_CHEMICAL_TYPE_FAIL = 'ASSIGN_CHEMICAL_TYPE_FAIL';

const GET_CHEMICAL_TYPE_LIST_SUCCESS = 'GET_CHEMICAL_TYPE_LIST_SUCCESS';
const GET_CHEMICAL_TYPE_LIST_FAIL = 'GET_CHEMICAL_TYPE_LIST_FAIL';

const DELETE_CHEMICAL_TYPE_SUCCESS = 'DELETE_CHEMICAL_TYPE_SUCCESS';
const DELETE_CHEMICAL_TYPE_FAIL = 'DELETE_CHEMICAL_TYPE_FAIL';

const UPDATE_CHEMICAL_TYPE_SUCCESS = 'UPDATE_CHEMICAL_TYPE_SUCCESS';
const UPDATE_CHEMICAL_TYPE_FAIL = 'UPDATE_CHEMICAL_TYPE_FAIL';

const CLEAR_REMOVED_FLAG = 'CLEAR_REMOVED_FLAG';


// FOR CHEMICAL TYPE SETTING PAGE

export const getChemicalType = (operatorId) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_CHEMICAL_TYPE_ERROR });
        dispatch({ type: SET_CHEMICAL_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await getChemicalTypeAction(operatorId, token);

        dispatch({ type: GET_CHEMICAL_TYPE_LIST_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_CHEMICAL_TYPE_LIST_FAIL, payload: returnError(error) });
    }
};

export const addChemicalType = (newChemicalType) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_CHEMICAL_TYPE_ERROR });
        dispatch({ type: SET_CHEMICAL_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await addChemicalTypeAction(newChemicalType, token);

        dispatch({ type: ADD_CHEMICAL_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ADD_CHEMICAL_TYPE_FAIL, payload: returnError(error) });
    }
};

export const assignChemicalType = (newChemicalType) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_CHEMICAL_TYPE_ERROR });
        dispatch({ type: SET_CHEMICAL_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await assignChemicalTypeAction(newChemicalType, token);

        dispatch({ type: ASSIGN_CHEMICAL_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ASSIGN_CHEMICAL_TYPE_FAIL, payload: returnError(error) });
    }
};


export const editChemicalType = (updateData) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_CHEMICAL_TYPE_ERROR });
        dispatch({ type: SET_CHEMICAL_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await editChemicalTypeAction(updateData, token);

        dispatch({ type: UPDATE_CHEMICAL_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: UPDATE_CHEMICAL_TYPE_FAIL, payload: returnError(error) });
    }
};

export const deleteChemicalType = (resBody) => async (dispatch, getState) => {
    try {
        dispatch({ type: CLEAR_CHEMICAL_TYPE_ERROR });
        dispatch({ type: SET_CHEMICAL_TYPE_LOADING });

        const { user } = getState();
        const token = getToken(user);

        const { data } = await deleteChemicalTypeAction(resBody, token);

        dispatch({ type: DELETE_CHEMICAL_TYPE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: DELETE_CHEMICAL_TYPE_FAIL, payload: returnError(error) });
    }
};

export const clearRemovedFlag = () => ({
    type: CLEAR_REMOVED_FLAG,
});

const initialState = {
    language: 'en',
    chemicalTypeList: null,
    loading: false,
    chemicalTypeLists: [],
};

export const chemicalTypeSettingReducer = function (state = initialState, action) {
    switch (action.type) {
        case SET_CHEMICAL_TYPE_LOADING:
            return {
                ...state,
                loading: true,
            };

        case CLEAR_CHEMICAL_TYPE_ERROR:
            return {
                ...state,
                error: null,
            };

        case GET_CHEMICAL_TYPE_LIST_SUCCESS:
            return {
                ...state,
                chemicalTypeLists: action.payload.data,
                loading: false,
                error: null,
            };
        case ADD_CHEMICAL_TYPE_SUCCESS:
            return {
                ...state,
                chemicalType: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };
        case ASSIGN_CHEMICAL_TYPE_SUCCESS:
            return {
                ...state,
                assigned: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };
        case UPDATE_CHEMICAL_TYPE_SUCCESS:
            return {
                ...state,
                chemicalTypeUpdated: action.payload.data,
                loading: false,
                error: null,
                success: true,
            };
        case DELETE_CHEMICAL_TYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                removed: true,
            };

        case CLEAR_REMOVED_FLAG:
            return {
                ...state,
                removed: false,
            };

        case LOGOUT_SUCCESS:
            return initialState;

        case GET_CHEMICAL_TYPE_LIST_FAIL:
        case ASSIGN_CHEMICAL_TYPE_FAIL:
        case UPDATE_CHEMICAL_TYPE_FAIL:
        case DELETE_CHEMICAL_TYPE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};
