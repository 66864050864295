import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LockOutlined, EyeInvisibleOutlined, EyeTwoTone, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, message } from 'antd';
import './operator.css';
import '../Sales Performance/salesperformance.css';
import laundroLogo from '../../../images/logo/laundrologo.png';
import cleanproLogo from '../../../images/logo/cleanprologo.png';
import octopusLogo from '../../../images/logo/octopuslogo.png';
import washstudioLogo from '../../../images/washstudio/washstudioLogo.png';
import cuciLogo from '../../../images/cuciExpress/cuci-whiteLogo.png';
import aerosparkleLogo from '../../../images/logo/aerosparklelogo.png';
import bubblepulseLogo from '../../../images/logo/bubblepulseLogo.png';

import { resetPassword } from '../../../reducer/userReducer';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const VerifyResetPassword = () => {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const { theme } = useSelector((state) => state.theme);
	const [slogan, setSlogan] = useState('');
	const [text, setText] = useState('');
	const [logo, setLogo] = useState(null);
	const { t } = useTranslation();
	const token = searchParams.get('token');
	const [passwordVisible, setPasswordVisible] = React.useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [userType, setUserType] = useState('');
	// console.log('token', token);
	// console.log(' window.location.origin', window.location.origin);
	// console.log(UserContext);
	const onFinish = (values) => {
		const data = {
			newPassword: values.newPassword,
			token: token,
		};
		dispatch(resetPassword(data)).then((res) => {
			if (res && res.data) {
				setUserType(res.role);
				setIsModalOpen(true);
			}

			if (res && res.en) {
				return message.error(res.en);
			}
		});
	};

	useEffect(() => {
		if (theme === 'laundro') {
			setSlogan('Digitize Your Laundromat');
			setText('Manage your laundry at your fingertips.');
			setLogo(laundroLogo);
		} else if (theme === 'cleanpro') {
			setSlogan('Cleanpro Laundry Management System');
			setText('Integrated Self Service Laundry Management');
			setLogo(cleanproLogo);
		} else if (theme === 'octopus') {
			setSlogan('Octopus Laundry Management System');
			setText('Your Success is our success');
			setLogo(octopusLogo);
		} else if (theme === 'washstudio') {
			setSlogan('Evolve the Future');
			setText('Better Insight with Smarter Machines');
			setLogo(washstudioLogo);
		} else if (theme === 'cuci') {
			setSlogan('Easy & Fast Laundromate');
			setText('Smart Laundry Management System.');
			setLogo(cuciLogo);
		} else if (theme === 'aerosparkle') {
			setSlogan('Sparkling clean laundry');
			setText('Sparkling clean laundry');
			setLogo(aerosparkleLogo)
		  } else if (theme === 'bubblespulse') {
			setSlogan('The Urban Laundry Experience');
			setText('The Urban Laundry Experience');
			setLogo(bubblepulseLogo);
		}
	}, [theme]);

	return (
		<div>
			<Modal open={isModalOpen} onOk={() => setIsModalOpen(false)} onCancel={() => setIsModalOpen(false)} footer={''}>
				<div className='text-center py-5'>
					<CheckCircleOutlined style={{ color: 'green', fontSize: '6rem' }} />
					<h3 className='text-capitalize mt-3'> Password Changed! </h3>
					<p className='text-capitalize'> Please login with your new password </p>
					<Link to={`/${userType}_login`}>
						<Button className='mt-5' type='primary' style={{ width: '280px' }}>
							{t('Login Account')}
						</Button>
					</Link>
				</div>
			</Modal>

			<div className='login-main-container d-sm-flex justify-content-evenly'>
				<div className='mx-3 pt-3'>
					<div>
						{theme === 'washstudio' ? (
							<div>
								<div className='d-flex justify-content-center'>
									<img className='laundro-login-logo pb-4' src={logo} alt='brand logo' />
								</div>

								<h2 className='text-center text-white heading-text'>{slogan}</h2>
								<div className='py-4 text-center' style={{ color: 'white' }}>
									{text}
								</div>
							</div>
						) : (
							<div className={theme === 'cuci' ? 'heading-text' : ' text-white heading-text'}>
								<img className='laundro-login-logo pb-4' src={logo} alt='brand logo' />
								<h2 className={theme !== 'cuci' && 'text-white'}>{slogan}</h2>
								<div className='py-4 fw-bold'>{text}</div>
							</div>
						)}
					</div>
					<div className='pt-4 desktop-terms' style={{ textAlign: theme === 'washstudio' ? 'center' : 'start' }}>
						<a className={theme === 'cuci' ? 'login-link-2 ' : 'login-link'} href='/terms&conditions'>
							{t('Terms & Conditions')}
						</a>{' '}
						<a className={theme === 'cuci' ? 'login-link-2' : 'login-link'} href='/privacypolicy'>
							{t('Privacy & Policy')}
						</a>
					</div>
				</div>
				<div className='card mx-3  py-5 px-4' style={{ maxWidth: '500px', minWidth: '30vw' }}>
					<div className='mb-2'>
						<h2 className='text-capitalize'> Enter New Password </h2>
					</div>
					<Form name='send-reset-email' layout='vertical' onFinish={onFinish} className='mb-3'>
						<Form.Item
							name='newPassword'
							rules={[
								{
									required: true,
									message: t('Required'),
								},
							]}
							label={t('New Password')}
						>
							<Input.Password
								prefix={<LockOutlined className='site-form-item-icon' />}
								type='password'
								placeholder={t('Password')}
								visibilityToggle={{
									visible: passwordVisible,
									onVisibleChange: setPasswordVisible,
								}}
								iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
							/>
						</Form.Item>

						<Button type='primary' htmlType='submit' block>
							{t('Submit')}
						</Button>
					</Form>
				</div>

				<div className='mobile-terms text-center pb-3'>
					<a className='login-link' href='/terms&conditions'>
						{t('Terms & Conditions')}
					</a>

					<a className='login-link' href='/privacypolicy'>
						{t('Privacy & Policy')}
					</a>
				</div>
			</div>
		</div>
	);
};

export default VerifyResetPassword;
