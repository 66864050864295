import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Alert } from 'antd';
import './operator.css';
import '../Sales Performance/salesperformance.css';
import laundroLogo from '../../../images/logo/laundrologo.png';
import cleanproLogo from '../../../images/logo/cleanprologo.png';
import octopusLogo from '../../../images/logo/octopuslogo.png';
import washstudioLogo from '../../../images/washstudio/washstudioLogo.png';
import cuciLogo from '../../../images/cuciExpress/cuci-whiteLogo.png';
import aerosparkleLogo from '../../../images/logo/aerosparklelogo.png';
import bubblepulseLogo from '../../../images/logo/bubblepulseLogo.png';

import { loginOperator, sendResetEmail } from '../../../reducer/userReducer';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ForgotPasswordPage = () => {
	const dispatch = useDispatch();

	const [searchParams] = useSearchParams();
	const { theme } = useSelector((state) => state.theme);
	const [slogan, setSlogan] = useState('');
	const [text, setText] = useState('');
	const [logo, setLogo] = useState(null);
	const { t } = useTranslation();
	const userType = searchParams.get('userType');
	const [loading, setLoading] = useState(false);
	// console.log(' window.location.origin', window.location.origin);
	// console.log(UserContext);
	const onFinish = (values) => {
		setLoading(true);
		const data = {
			brand: theme,
			email: values.email,
			role: userType,
			domain: window.location.origin,
		};
		dispatch(sendResetEmail(data)).then((res) => {
			setLoading(false);

			if (res && res.data) {
				return message.success(t('Email sent successfully'));
			}

			if (res && res.en) {
				return message.error(res.en);
			}
		});
	};

	useEffect(() => {
		if (theme === 'laundro') {
			setSlogan('Digitize Your Laundromat');
			setText('Manage your laundry at your fingertips.');
			setLogo(laundroLogo);
		} else if (theme === 'cleanpro') {
			setSlogan('Cleanpro Laundry Management System');
			setText('Integrated Self Service Laundry Management');
			setLogo(cleanproLogo);
		} else if (theme === 'octopus') {
			setSlogan('Octopus Laundry Management System');
			setText('Your Success is our success');
			setLogo(octopusLogo);
		} else if (theme === 'washstudio') {
			setSlogan('Evolve the Future');
			setText('Better Insight with Smarter Machines');
			setLogo(washstudioLogo);
		} else if (theme === 'cuci') {
			setSlogan('Easy & Fast Laundromate');
			setText('Smart Laundry Management System.');
			setLogo(cuciLogo);
		} else if (theme === 'aerosparkle') {
			setSlogan('Sparkling clean laundry');
			setText('Sparkling clean laundry');
			setLogo(aerosparkleLogo);
		} else if (theme === 'bubblespulse') {
			setSlogan('The Urban Laundry Experience');
			setText('The Urban Laundry Experience');
			setLogo(bubblepulseLogo);
		}
	}, [theme]);

	return (
		<div className='login-main-container d-sm-flex justify-content-evenly'>
			<div className='mx-3 pt-3'>
				<div>
					{theme === 'washstudio' ? (
						<div>
							<div className='d-flex justify-content-center'>
								<img className='laundro-login-logo pb-4' src={logo} alt='brand logo' />
							</div>

							<h2 className='text-center text-white heading-text'>{slogan}</h2>
							<div className='py-4 text-center' style={{ color: 'white' }}>
								{text}
							</div>
						</div>
					) : (
						<div className={theme === 'cuci' ? 'heading-text' : ' text-white heading-text'}>
							<img className='laundro-login-logo pb-4' src={logo} alt='brand logo' />
							<h2 className={theme !== 'cuci' && 'text-white'}>{slogan}</h2>
							<div className='py-4 fw-bold'>{text}</div>
						</div>
					)}
				</div>
				<div className='pt-4 desktop-terms' style={{ textAlign: theme === 'washstudio' ? 'center' : 'start' }}>
					<a className={theme === 'cuci' ? 'login-link-2 ' : 'login-link'} href='/terms&conditions'>
						{t('Terms & Conditions')}
					</a>{' '}
					<a className={theme === 'cuci' ? 'login-link-2' : 'login-link'} href='/privacypolicy'>
						{t('Privacy & Policy')}
					</a>
				</div>
			</div>
			<div className='card mx-3  py-5 px-4' style={{ maxWidth: '500px', minWidth: '30vw' }}>
				<div className='mb-2'>
					<h2 className='text-capitalize'> Forgot Your Password? </h2>
					<p>{t('We will email you a link to reset your password.')}</p>
				</div>
				<p className='py-3'>{t('Please enter your email to reset')}</p>
				<Form name='send-reset-email' onFinish={onFinish}>
					<Form.Item
						name='email'
						rules={[
							{
								required: true,
								message: t('Required'),
							},
						]}
						className='mb-5'
					>
						<Input prefix={<MailOutlined className='site-form-item-icon' />} placeholder={t('Email')} />
					</Form.Item>

					<Button loading={loading} type='primary' htmlType='submit' block>
						{t('Send me a password reset link')}
					</Button>
				</Form>

				<div className='mt-3'>
					{userType === 'distributor' && (
						<>
							<Link to='/distributor_login'>
								<Button type='secondary' block>
									{t('Cancel')}
								</Button>
							</Link>
						</>
					)}
					{userType === 'operator' && (
						<>
							<Link to='/operator_login'>
								<Button type='secondary' block>
									{t('Cancel')}
								</Button>
							</Link>
						</>
					)}
					{userType === 'staff' && (
						<>
							<Link to='/staff_login'>
								{' '}
								<Button type='secondary' block>
									{t('Cancel')}
								</Button>
							</Link>
						</>
					)}
				</div>
			</div>

			<div className='mobile-terms text-center pb-3'>
				<a className='login-link' href='/terms&conditions'>
					{t('Terms & Conditions')}
				</a>

				<a className='login-link' href='/privacypolicy'>
					{t('Privacy & Policy')}
				</a>
			</div>
		</div>
	);
};

export default ForgotPasswordPage;
