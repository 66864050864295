import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../components/loading';

const CleanProTheme = lazy(() => import('./cleanproTheme'));
const OctopusTheme = lazy(() => import('./octopusTheme'));
const LaundroTheme = lazy(() => import('./laundroTheme'));

const WashStudioTheme = lazy(() => import('./washstudioTheme'));
const CuciExpressTheme = lazy(() => import('./cuciTheme'));
const AerosparkleTheme = lazy(() => import('./aerosparkleTheme'));
const BubblespulseTheme = lazy(() => import('./bubblespulseTheme'));

const ThemeController = ({ children }) => {
	const { theme } = useSelector((state) => state.theme);
	// console.log('theme', theme)

	const themeController = () => {
		if (theme === 'laundro') return <LaundroTheme />;
		else if (theme === 'cleanpro') return <CleanProTheme />;
		else if (theme === 'octopus') return <OctopusTheme />;
		else if (theme === 'washstudio') return <WashStudioTheme />;
		else if (theme === 'cuci') return <CuciExpressTheme />;
		else if (theme === 'aerosparkle') return <AerosparkleTheme />;
		else if (theme === 'bubblespulse') return <BubblespulseTheme />;
	};

	return (
		<>
			<Suspense fallback={<Loading></Loading>}>
				{themeController()}
				{children}
			</Suspense>
		</>
	);
};

export default ThemeController;
